import React from 'react';
import { useGetChapterUsersQuery } from '../../../../features/chapters/chaptersApiSlice';
import { useUpdateUserMutation } from '../../../../features/users/usersApiSlice';
import Loading from '../../public/Loading';
import useAuth from '../../../../hooks/useAuth';

const MembersList = ({ chapterId }) => {
    const { data: users = [], isLoading, isError, refetch } = useGetChapterUsersQuery({ chapterId, verified: true });
    const { roles } = useAuth();
    const [updateUser] = useUpdateUserMutation();

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumberString;
    };

    const handleRoleChange = async (user) => {
        const userRoles = user.roles || []; // Ensure roles is at least an empty array
        const newRoles = userRoles.includes('Officer')
            ? userRoles.filter(role => role !== 'Officer')  // Demote
            : [...userRoles, 'Officer'];                     // Promote

        try {
            await updateUser({ id: user._id, roles: newRoles }).unwrap();
            refetch(); // Refetch the data after successful update
        } catch (err) {
            console.error('Failed to update roles:', err);
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return <div>Error loading users.</div>;
    }

    return (
        <div className="overflow-x-auto w-full">
            <table className="min-w-full bg-white shadow-md rounded-lg">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b bg-gray-100 border-r">Last Name</th>
                        <th className="py-2 px-4 border-b bg-gray-100 border-r">First Name</th>
                        <th className="py-2 px-4 border-b bg-gray-100 border-r">ASUID</th>
                        <th className="py-2 px-4 border-b bg-gray-100 border-r">Email Address</th>
                        <th className="py-2 px-4 border-b bg-gray-100">Phone Number</th>
                        {roles.includes('Officer') && (
                            <th className="py-2 px-4 border-b bg-gray-100">Actions</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user._id} className="hover:bg-gray-50">
                            <td className="py-2 px-4 border-b border-r">{user.lastName}</td>
                            <td className="py-2 px-4 border-b border-r">{user.firstName}</td>
                            <td className="py-2 px-4 border-b border-r">{user.studentId}</td>
                            <td className="py-2 px-4 border-b border-r">{user.email}</td>
                            <td className="py-2 px-4 border-b">{formatPhoneNumber(user.phoneNumber)}</td>
                            {roles.includes('Officer') && (
                                <td className="py-2 px-4 border-b">
                                    <button
                                        onClick={() => handleRoleChange(user)}
                                        className={`py-1 px-3 rounded ${
                                            user.roles?.includes('Officer') ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
                                        } text-white`}
                                    >
                                        {user.roles?.includes('Officer') ? 'Demote' : 'Promote'}
                                    </button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MembersList;