import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const chaptersAdapter = createEntityAdapter({});

const initialState = chaptersAdapter.getInitialState();

export const chaptersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getChapters: builder.query({
            query: () => '/chapters',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: responseData => {
                const loadedChapters = responseData.map(chapter => {
                    chapter.id = chapter._id;
                    return chapter;
                });
                return chaptersAdapter.setAll(initialState, loadedChapters);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Chapter', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Chapter', id }))
                    ];
                } else return [{ type: 'Chapter', id: 'LIST' }];
            }
        }),
        getChapter: builder.query({
            query: chapterId => `/chapters/${chapterId}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            providesTags: (result, error, arg) => [{ type: 'Chapter', id: arg }]
        }),
        getChapterUsers: builder.query({
            query: ({ chapterId, verified }) => ({
                url: `/chapters/${chapterId}/users`,
                params: { verified },
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            providesTags: (result, error, { chapterId }) => [{ type: 'Chapter', id: chapterId }]
        }),
        getChapterPnms: builder.query({
            query: chapterId => `/chapters/${chapterId}/pnms`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            providesTags: (result, error, arg) => [{ type: 'Chapter', id: arg }]
        }),
        getChapterEventsByType: builder.query({
            query: ({ chapterId, type }) => ({
                url: `/chapters/${chapterId}/events`,
                params: { type },
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            providesTags: (result, error, { chapterId }) => [{ type: 'Chapter', id: chapterId }]
        }),
        getEventAttendance: builder.query({
            query: ({ chapterId, type }) => ({
                url: `/chapters/${chapterId}/events/attendance`,
                method: 'GET',
                body: { type }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            providesTags: (result, error, arg) => [{ type: 'Chapter', id: arg.chapterId }]
        }),
        addNewChapter: builder.mutation({
            query: initialChapterData => ({
                url: '/chapters',
                method: 'POST',
                body: {
                    ...initialChapterData,
                }
            }),
            invalidatesTags: [
                { type: 'Chapter', id: 'LIST' }
            ]
        }),
        updateChapter: builder.mutation({
            query: initialChapterData => ({
                url: '/chapters',
                method: 'PATCH',
                body: {
                    ...initialChapterData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Chapter', id: arg.id }
            ]
        }),
        verifyUser: builder.mutation({
            query: ({ chapterId, userId, approve }) => ({
                url: `/chapters/${chapterId}/users/${userId}/verify`,
                method: 'PATCH',
                body: { approve },
            }),
            invalidatesTags: (result, error, { chapterId }) => [{ type: 'Chapter', id: chapterId }]
        }),
        deleteChapter: builder.mutation({
            query: ({ id }) => ({
                url: '/chapters',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Chapter', id: arg.id }
            ]
        }),
    }),
});

export const {
    useGetChaptersQuery,
    useGetChapterQuery,
    useGetChapterUsersQuery,
    useGetChapterPnmsQuery,
    useGetChapterEventsByTypeQuery,
    useGetEventAttendanceQuery,
    useAddNewChapterMutation,
    useUpdateChapterMutation,
    useVerifyUserMutation,
    useDeleteChapterMutation
} = chaptersApiSlice;

export const selectChaptersResult = chaptersApiSlice.endpoints.getChapters.select();

const selectChaptersData = createSelector(
    selectChaptersResult,
    chaptersResult => chaptersResult.data
);

export const {
    selectAll: selectAllChapters,
    selectById: selectChapterById,
    selectIds: selectChapterIds
} = chaptersAdapter.getSelectors(state => selectChaptersData(state) ?? initialState);