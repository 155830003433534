import React from 'react';
import { useGetChapterUsersQuery, useVerifyUserMutation } from '../../../../features/chapters/chaptersApiSlice';
import { AiOutlineClose, AiOutlineCheck, AiFillCloseCircle } from 'react-icons/ai';
import Loading from '../../public/Loading';

const VerifyUsersModal = ({ chapterId, onClose }) => {
    const { data: users = [], isLoading, isError } = useGetChapterUsersQuery({ chapterId, verified: false });
    const [verifyUser] = useVerifyUserMutation();

    const handleVerify = async (userId, approve) => {
        try {
            await verifyUser({ chapterId, userId, approve }).unwrap();
        } catch (err) {
            console.error('Failed to verify user:', err);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
                <button onClick={onClose} className="absolute top-3 right-3 text-gray-500 hover:text-gray-700">
                    <AiOutlineClose size={24} />
                </button>

                <h2 className="text-xl font-bold mb-4 text-center">Verify Users</h2>

                {isLoading && <Loading />}
                {isError && <div>Error loading users.</div>}

                {!isLoading && !isError && users.length === 0 && (
                    <div className="text-center text-gray-700">No Users Pending Verification</div>
                )}

                {!isLoading && !isError && users.length > 0 && (
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b bg-gray-100">Name</th>
                                    <th className="py-2 px-4 border-b bg-gray-100"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr key={user._id} className="hover:bg-gray-50">
                                        <td className="py-2 px-4 border-b">{user.firstName} {user.lastName}</td>
                                        <td className="py-2 px-4 border-b text-right">
                                            <button
                                                onClick={() => handleVerify(user._id, true)}
                                                className="text-green-500 hover:text-green-600 mr-2"
                                                title="Approve"
                                            >
                                                <AiOutlineCheck size={24} />
                                            </button>
                                            <button
                                                onClick={() => handleVerify(user._id, false)}
                                                className="text-white bg-red-500 hover:bg-red-600 rounded-full p-1"
                                                title="Reject"
                                            >
                                                <AiFillCloseCircle size={24} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VerifyUsersModal;