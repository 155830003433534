import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const eventsAdapter = createEntityAdapter({});

const initialState = eventsAdapter.getInitialState();

export const eventsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEvents: builder.query({
            query: () => '/events',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: responseData => {
                const loadedEvents = responseData.map(event => {
                    event.id = event._id;
                    return event;
                });
                return eventsAdapter.setAll(initialState, loadedEvents);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Event', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Event', id }))
                    ];
                } else return [{ type: 'Event', id: 'LIST' }];
            }
        }),
        getPnmEvents: builder.mutation({
            query: ({ chapterIds, pnmId }) => ({
                url: '/events/pnm',
                method: 'POST',
                body: { chapterIds, pnmId },
            }),
        }),
        addNewEvent: builder.mutation({
            query: initialEventData => ({
                url: '/events',
                method: 'POST',
                body: { ...initialEventData },
            }),
            invalidatesTags: [
                { type: 'Event', id: 'LIST' }
            ]
        }),
        updateEvent: builder.mutation({
            query: initialEventData => ({
                url: '/events',
                method: 'PATCH',
                body: { ...initialEventData },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.id }
            ]
        }),
        deleteEvent: builder.mutation({
            query: ({ id }) => ({
                url: '/events',
                method: 'DELETE',
                body: { id },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.id }
            ]
        }),
        getEventAttendees: builder.query({
            query: (eventId) => `/events/${eventId}/attendees`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: (responseData) => responseData,
            providesTags: (result, error, arg) => [
                { type: 'Event', id: arg },
            ],
        }),
        rsvpToEvent: builder.mutation({
            query: ({ eventId, userId }) => ({
                url: `/events/${eventId}/rsvp`,
                method: 'PATCH',
                body: { userId },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.eventId },
            ],
        }),
        verifyAttendance: builder.mutation({
            query: ({ eventId, userId }) => ({
                url: `/events/${eventId}/verify`,
                method: 'PATCH',
                body: { userId },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.eventId },
            ],
        }),
    }),
});

export const {
    useGetEventsQuery,
    useGetPnmEventsMutation,
    useAddNewEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
    useGetEventAttendeesQuery,
    useRsvpToEventMutation,
    useVerifyAttendanceMutation,
} = eventsApiSlice;

export const selectEventsResult = eventsApiSlice.endpoints.getEvents.select();

const selectEventsData = createSelector(
    selectEventsResult,
    eventsResult => eventsResult.data
);

export const {
    selectAll: selectAllEvents,
    selectById: selectEventById,
    selectIds: selectEventIds,
} = eventsAdapter.getSelectors(state => selectEventsData(state) ?? initialState);