import React, { useState } from 'react';
import { useGetEventAttendeesQuery, useVerifyAttendanceMutation } from '../../../../features/events/eventsApiSlice';
import Loading from '../../public/Loading';
import { IoIosClose } from 'react-icons/io';

const AttendanceModal = ({ eventId, onClose }) => {
    const { data: attendees = [], isLoading, isError } = useGetEventAttendeesQuery(eventId);
    const [verifyAttendance] = useVerifyAttendanceMutation();
    const [searchQuery, setSearchQuery] = useState('');

    const handleVerifyAttendance = async (userId) => {
        try {
            await verifyAttendance({ eventId, userId }).unwrap();
        } catch (err) {
            console.error('Failed to verify attendance:', err);
        }
    };

    const filteredAttendees = attendees.filter(attendee =>
        `${attendee.user.firstName} ${attendee.user.lastName}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
    );

    if (isLoading) return <Loading />;
    if (isError) return <div>Error loading attendees</div>;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[80vh] overflow-y-auto">
                <div className="sticky top-0 bg-white z-10 p-4 border-b">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold text-center">Event Attendance</h2>
                        <button onClick={onClose}>
                            <IoIosClose size={28} />
                        </button>
                    </div>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search attendees..."
                        className="w-full p-2 border rounded"
                    />
                </div>
                <ul className="divide-y divide-gray-200 px-4 pb-4">
                    {filteredAttendees.length > 0 ? (
                        filteredAttendees.map(attendee => (
                            <li key={attendee.user._id} className="py-2 flex items-center justify-between">
                                <div className="flex items-center">
                                    <img
                                        src={attendee.user.profilePicture}
                                        alt={`${attendee.user.firstName} ${attendee.user.lastName}`}
                                        className="w-28 h-28 object-cover rounded-full shadow-lg drop-shadow-lg mr-4"
                                    />
                                    <span>{attendee.user.firstName} {attendee.user.lastName}</span>
                                </div>
                                {!attendee.verified && (
                                    <button
                                        onClick={() => handleVerifyAttendance(attendee.user._id)}
                                        className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600"
                                    >
                                        Verify
                                    </button>
                                )}
                            </li>
                        ))
                    ) : (
                        <li className="py-2 text-center">No attendees found</li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default AttendanceModal;