import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const usersAdapter = createEntityAdapter({});

const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: () => '/users',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: responseData => {
                const loadedUsers = responseData.map(user => {
                    user.id = user._id;
                    return user;
                });
                return usersAdapter.setAll(initialState, loadedUsers);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'User', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'User', id }))
                    ];
                } else return [{ type: 'User', id: 'LIST' }];
            }
        }),
        getUser: builder.query({
            query: userId => `/users/${userId}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            providesTags: (result, error, arg) => [{ type: 'User', id: arg }]
        }),
        getUserEvents: builder.query({
            query: (userId) => `/users/${userId}/events`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: (responseData) => responseData,
            providesTags: (result, error, arg) => [
                { type: 'User', id: arg },
            ],
        }),
        addNewUser: builder.mutation({
            query: (formData) => ({
                url: '/users',
                method: 'POST',
                body: formData
            }),
            invalidatesTags: [
                { type: 'User', id: 'LIST' }
            ]
        }),
        updateUser: builder.mutation({
            query: (formData) => ({
                url: '/users',
                method: 'PATCH',
                body: formData,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.get('id') }
            ]
        }),        
        followChapter: builder.mutation({
            query: ({ userId, chapterId, follow }) => ({
                url: `/users/${userId}/chapters/${chapterId}/follow`,
                method: 'PATCH',
                body: { follow }
            }),
            invalidatesTags: (result, error, { userId }) => [
                { type: 'User', id: userId }
            ]
        }),
        updatePriorityStatus: builder.mutation({
            query: ({ userId, chapterId, priority }) => ({
                url: `/users/${userId}/chapters/${chapterId}/priority`,
                method: 'PATCH',
                body: { priority },
            }),
            invalidatesTags: (result, error, { userId }) => [{ type: 'User', id: userId }],
        }),
        completeUserProfile: builder.mutation({
            query: ({ userId, homeCountry, gradeLevel, major, gpa }) => ({
                url: `/users/${userId}/completeProfile`,
                method: 'PATCH',
                body: { homeCountry, gradeLevel, major, gpa }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.userId }
            ]
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: '/users',
                method: 'DELETE',
                body: {userId}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg }
            ]
        }),        
    }),
});

export const {
    useGetUsersQuery,
    useGetUserQuery,
    useGetUserEventsQuery,
    useAddNewUserMutation,
    useUpdateUserMutation,
    useFollowChapterMutation,
    useUpdatePriorityStatusMutation,
    useCompleteUserProfileMutation,
    useDeleteUserMutation
} = usersApiSlice;

export const selectUsersResult = usersApiSlice.endpoints.getUsers.select();

const selectUsersData = createSelector(
    selectUsersResult,
    usersResult => usersResult.data
);

export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds
} = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState);