import Navbar from "../Navbar";

const Active = () => {
    return (
        <div className="flex flex-col md:flex-row h-svh">
            <Navbar />
            <div className="flex-grow p-4">
                <div className="flex flex-col items-center justify-center h-full">
                    <h1 className="text-2xl font-bold mb-4">Welcome to One Greek</h1>
                    <p className="text-lg text-center mb-8">
                        Use the navigation bar to explore the different sections of the application:
                    </p>
                    <ul className="list-disc text-lg pl-6">
                        <li>The <strong>Events</strong> tab is where you can create and view events.</li>
                        <li>The <strong>Chapter</strong> tab allows you to view the roster.</li>
                        <li>The <strong>Recruitment</strong> tab is for viewing your PNM list and managing the bid list.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Active;