import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentToken, setCredentials } from './authSlice';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useRefreshMutation } from './authApiSlice';
import { Navigate, Outlet } from 'react-router-dom';
import Loading from '../../components/pages/public/Loading';
import { useGetUserQuery } from '../users/usersApiSlice';
import useAuth from '../../hooks/useAuth';
import { useGetChapterEventsByTypeQuery } from '../chapters/chaptersApiSlice';
import { setEvents } from '../events/eventsSlice';
import { useGetPnmEventsMutation } from '../events/eventsApiSlice';

const PersistLogin = () => {
    const dispatch = useDispatch();
    const persist = JSON.parse(localStorage.getItem('persist')) || false;
    const token = useSelector(selectCurrentToken);
    const effectRan = useRef(false);
    const [trueSuccess, setTrueSuccess] = useState(false);

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
    }] = useRefreshMutation();

    const { id } = useAuth();
    const { data: user, isFetching: userFetching } = useGetUserQuery(id, { skip: !id });
    const { data: activeEvents = [] } = useGetChapterEventsByTypeQuery(
        { chapterId: user?.chapter },
        { skip: !user?.chapter || !user?.roles?.includes('Active') || !token }
    );
    const [getPnmEvents] = useGetPnmEventsMutation();

    const fetchPnmEvents = useCallback(async () => {
        if (user?.roles.includes('PNM') && user?.pnmInfo?.chaptersFollowing?.length > 0) {
            const followedChapters = user.pnmInfo.chaptersFollowing.map(follow => follow.chapter.toString());
            try {
                const result = await getPnmEvents({ chapterIds: followedChapters, pnmId: id }).unwrap();
                if (Array.isArray(result)) {
                    const sortedEvents = [...result].sort((a, b) => new Date(a.start) - new Date(b.start));
                    dispatch(setEvents({ events: sortedEvents }));
                } else {
                    console.error('Unexpected response format:', result);
                }
            } catch (error) {
                console.error('Failed to fetch PNM events:', error);
            }
        }
    }, [user, getPnmEvents, id, dispatch]);

    useEffect(() => {
        if (user && user?.roles.includes('PNM') && id) {
            fetchPnmEvents();
        }
    }, [fetchPnmEvents, user, id]);

    useEffect(() => {
        if (effectRan.current === true || process.env.NODE_ENV !== 'development') {
            const verifyRefreshToken = async () => {
                try {
                    await refresh();
                    setTrueSuccess(true);
                } catch (err) {
                    console.log(err);
                }
            };
            if (!token && persist) verifyRefreshToken();
        }
        return () => {
            effectRan.current = true;
        };
    }, [persist, refresh, token]);

    useEffect(() => {
        if (user && token) {
            dispatch(setCredentials({ user }));
        }
    }, [user, dispatch, token]);

    useEffect(() => {
        if (user?.roles.includes('Active') && activeEvents.length > 0 && token) {
            dispatch(setEvents({ events: activeEvents }));
        }
    }, [activeEvents, dispatch, token, user]);

    let content;
    if (!persist) {
        content = <Outlet />;
    } else if (isLoading || userFetching) {
        content = <Loading />;
    } else if (isError) {
        content = <Navigate to="/" />;
    } else if (isSuccess && trueSuccess) {
        content = <Outlet />;
    } else if (token && isUninitialized) {
        content = <Outlet />;
    }

    return content;
};

export default PersistLogin;