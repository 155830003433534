import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import PnmTable from './PnmTable';
import PnmDetailsModal from './PnmDetailsModal';
import BidListModal from './BidListModal';
import { useGetChapterPnmsQuery } from '../../../../features/chapters/chaptersApiSlice';
import useAuth from '../../../../hooks/useAuth';
import Loading from '../../public/Loading';
import { useSelector } from 'react-redux';
import { selectCurrentEvents } from '../../../../features/events/eventsSlice';

const ActiveRecruitment = () => {
    const { roles, chapter } = useAuth();
    const { data: initialPnms = [], isLoading: pnmsLoading } = useGetChapterPnmsQuery(chapter, { skip: !chapter });

    const events = useSelector(selectCurrentEvents);
    const recruitmentEvents = events.filter(event => event.type === 'Recruitment');

    const [pnms, setPnms] = useState([]);
    const [selectedPnmIndex, setSelectedPnmIndex] = useState(null);
    const [sortedPnms, setSortedPnms] = useState([]);
    const [isBidListModalOpen, setIsBidListModalOpen] = useState(false);

    useEffect(() => {
        if (initialPnms.length > 0) {
            setPnms(initialPnms);
        }
    }, [initialPnms]);

    const handleSelectPnm = (pnm, sortedPnmsArray) => {
        const index = sortedPnmsArray.findIndex(p => p._id === pnm._id);
        setSelectedPnmIndex(index);
        setSortedPnms(sortedPnmsArray);
    };

    const handleClosePnmDetails = () => {
        setSelectedPnmIndex(null);
    };

    const handleNextPnm = () => {
        setSelectedPnmIndex((prevIndex) => (prevIndex + 1) % sortedPnms.length);
    };

    const handlePrevPnm = () => {
        setSelectedPnmIndex((prevIndex) => (prevIndex - 1 + sortedPnms.length) % sortedPnms.length);
    };

    const handleToggleBidList = () => {
        setIsBidListModalOpen(!isBidListModalOpen);
    };

    const handlePriorityChange = (pnmId, priority) => {
        setPnms(prevPnms =>
            prevPnms.map(pnm =>
                pnm._id === pnmId
                    ? {
                        ...pnm,
                        pnmInfo: {
                            ...pnm.pnmInfo,
                            chaptersFollowing: pnm.pnmInfo.chaptersFollowing.map(chap =>
                                chap.chapter.toString() === chapter.toString()
                                    ? { ...chap, priority }
                                    : chap
                            )
                        }
                    }
                    : pnm
            )
        );
    };

    if (!chapter || pnmsLoading) {
        return <Loading />;
    }

    return (
        <div className="flex md:flex-row flex-col h-screen">
            <Navbar />
            <div className="flex-grow p-4 flex flex-col overflow-hidden">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-bold">Active Recruitment</h1>
                    <button
                        onClick={handleToggleBidList}
                        className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                    >
                        View Bid List
                    </button>
                </div>
                <div className="flex-grow overflow-auto">
                    <PnmTable pnms={pnms} onSelectPnm={handleSelectPnm} />
                </div>
            </div>
            {selectedPnmIndex !== null && sortedPnms.length > 0 && (
                <PnmDetailsModal
                    pnm={sortedPnms[selectedPnmIndex]}
                    chapterId={chapter}
                    recruitmentEvents={recruitmentEvents}
                    onClose={handleClosePnmDetails}
                    onNext={handleNextPnm}
                    onPrev={handlePrevPnm}
                    isOfficer={roles.includes('Officer')}
                    onPriorityChange={handlePriorityChange}
                />
            )}
            {isBidListModalOpen && (
                <BidListModal onClose={handleToggleBidList} pnms={pnms} />
            )}
        </div>
    );
};

export default ActiveRecruitment;