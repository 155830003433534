import React, { useState } from 'react';
import Navbar from '../Navbar';
import CreateEventModal from './CreateEventModal';
import EventCard from './EventCard';
import useAuth from '../../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { selectCurrentEvents } from '../../../../features/events/eventsSlice';

const ActiveEvents = () => {
    const { chapter, roles, organization } = useAuth();
    const events = useSelector(selectCurrentEvents);
    const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
    const [editEvent, setEditEvent] = useState(null);

    const handleToggleCreateEventModal = () => {
        setIsCreateEventModalOpen(!isCreateEventModalOpen);
        setEditEvent(null);
    };

    const handleEditEvent = (event) => {
        setEditEvent(event);
        setIsCreateEventModalOpen(true);
    };

    return (
        <div className="flex flex-col md:flex-row h-screen">
            <Navbar />
            <div className="flex-grow p-4 flex flex-col overflow-hidden">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-bold">Active Events</h1>
                    {roles.includes('Officer') && (
                        <button
                            onClick={handleToggleCreateEventModal}
                            className="bg-event-card-button text-white py-2 px-4 rounded hover:bg-event-card-button-hover"
                        >
                            Create Event
                        </button>
                    )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {events.map(event => (
                        <EventCard 
                            key={event._id} 
                            event={event} 
                            isOfficer={roles.includes('Officer')}
                            onEditEvent={handleEditEvent}
                        />
                    ))}
                </div>
            </div>
            {isCreateEventModalOpen && (
                <CreateEventModal 
                    chapterId={chapter} 
                    organizationId={organization} 
                    onClose={handleToggleCreateEventModal} 
                    event={editEvent}
                />
            )}
        </div>
    );
};

export default ActiveEvents;