import React, { useState } from 'react';
import Navbar from '../Navbar';
import MembersList from './MembersList';
import VerifyUsersModal from './VerifyUsersModal';
import useAuth from '../../../../hooks/useAuth';
import Loading from '../../public/Loading';

const ActiveChapter = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { roles, chapter } = useAuth();

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    if (!chapter) {
        return <Loading />
    }

    return (
        <div className="flex flex-col md:flex-row h-screen">
            <Navbar />
            <div className="flex-grow p-4 flex flex-col">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-bold">Active Chapter</h1>
                    {roles.includes('Officer') && (
                        <button
                            onClick={handleOpenModal}
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                        >
                            Verify Users
                        </button>
                    )}
                </div>
                <div className="flex-grow overflow-auto">
                    <MembersList chapterId={chapter} />
                </div>
            </div>
            {isModalOpen && <VerifyUsersModal chapterId={chapter} onClose={handleCloseModal} />}
        </div>
    );
};

export default ActiveChapter;