import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSendLogoutMutation } from '../../../features/auth/authApiSlice';
import { IoIosMenu, IoIosClose } from 'react-icons/io';
import Logo from '../../../img/logo-alt-white.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [sendLogout] = useSendLogoutMutation();

    const toggleMenu = () => setIsOpen(!isOpen);
    const navigate = useNavigate();

    return (
        <>
            <div className="hidden md:flex md:flex-col bg-pacific-blue text-white w-60 p-4">
                <img
                    src={Logo}
                    alt="Logo Alt White"
                    className="w-40 mb-8 cursor-pointer"
                    onClick={() => navigate('/')}
                />
                <nav className="flex flex-col space-y-4">
                    <Link to="/" className="bg-deep-pacific-blue hover:bg-light-pacific-blue p-2 rounded">Dashboard</Link>
                    <Link to="/events" className="bg-deep-pacific-blue hover:bg-powder-blue p-2 rounded">Events</Link>
                    <Link to="/chapter" className="bg-deep-pacific-blue hover:bg-powder-blue p-2 rounded">Chapter</Link>
                    <Link to="/recruitment" className="bg-deep-pacific-blue hover:bg-powder-blue p-2 rounded">Recruitment</Link>
                </nav>
                <button
                    onClick={sendLogout}
                    className="mt-auto bg-deep-sky-blue hover:bg-powder-blue p-2 rounded"
                >
                    Log Out
                </button>
            </div>
            <div className="md:hidden bg-pacific-blue text-white w-full p-4 flex justify-between items-center relative">
                <img
                    src={Logo}
                    alt="Logo Alt White"
                    className="w-40"
                />
                <button onClick={toggleMenu} className="focus:outline-none">
                    {isOpen ? <IoIosClose size={32} /> : <IoIosMenu size={32} />}
                </button>
                {isOpen && (
                    <div className="absolute top-full left-0 right-0 bg-pacific-blue text-white flex flex-col p-4 z-50">
                        <nav className="flex flex-col space-y-4">
                            <Link to="/" onClick={toggleMenu} className="bg-deep-pacific-blue hover:bg-powder-blue p-2 rounded">Dashboard</Link>
                            <Link to="/events" onClick={toggleMenu} className="bg-deep-pacific-blue hover:bg-powder-blue p-2 rounded">Events</Link>
                            <Link to="/chapter" className="bg-deep-pacific-blue hover:bg-powder-blue p-2 rounded">Chapter</Link>
                            <Link to="/recruitment" className="bg-deep-pacific-blue hover:bg-powder-blue p-2 rounded">Recruitment</Link>
                        </nav>
                        <button
                            onClick={() => {
                                toggleMenu();
                                sendLogout();
                            }}
                            className="mt-4 bg-deep-sky-blue hover:bg-powder-blue p-2 rounded"
                        >
                            Log Out
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default Navbar;