import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        user: null
    },
    reducers: {
        setCredentials: (state, action) => {
            const { accessToken, user } = action.payload;
            if (accessToken) state.token = accessToken;
            if (user) state.user = user;
        },
        logOut: (state, action) => {
            state.token = null;
            state.user = null;
        },
    }
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentUser = (state) => state.auth.user;