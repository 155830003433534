import React, { useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import AttendanceModal from './AttendanceModal';
import { useDeleteEventMutation } from '../../../../features/events/eventsApiSlice';
import useAuth from '../../../../hooks/useAuth';

const EventCard = ({ event, isOfficer, refetchEvents, onEditEvent }) => {
    const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState(false);
    const [deleteEvent] = useDeleteEventMutation();
    const { id: userId } = useAuth();

    const handleToggleAttendanceModal = () => {
        setIsAttendanceModalOpen(!isAttendanceModalOpen);
    };

    const handleDeleteEvent = async () => {
        try {
            await deleteEvent({ id: event._id }).unwrap();
            refetchEvents();
        } catch (err) {
            console.error('Failed to delete event:', err);
        }
    };

    const handleEditEvent = () => {
        onEditEvent(event);
    };

    const mapLink = getMapsLink(event.location?.address || '');

    const formattedDate = formatEventDate(event.start, event.end);

    const isRelativeDate = formattedDate.startsWith('Today') || formattedDate.startsWith('Tomorrow');
    const datePart = isRelativeDate ? formattedDate.split(' ')[0] : formattedDate.split(' ').slice(0, 3).join(' ');
    const timePart = isRelativeDate ? formattedDate.split(' ').slice(1).join(' ') : formattedDate.split(' ').slice(3).join(' ');

    return (
        <div className="bg-event-card-back shadow-md py-4 flex justify-evenly items-center">
            <div className="flex flex-col bg-event-card-left rounded-lg p-4 items-center w-1/2">
                <p className="text-lg font-bold mb-1">{event.type}</p>
                <p className="text-black mb-1">{event.location?.name || 'No location specified'}</p>
                {event.location?.address && (
                    <a href={mapLink} target="_blank" rel="noopener noreferrer" className="text-gray-600 underline text-center">
                        {event.location.address}
                    </a>
                )}
            </div>
            <div className="flex flex-col items-center">
                <p className="text-lg font-bold">{datePart}</p>
                <p className="text-gray-600 mb-2">{timePart}</p>
                {isOfficer && (
                    <>
                        <button
                            onClick={handleToggleAttendanceModal}
                            className="bg-event-card-button text-white py-2 px-4 rounded shadow hover:bg-event-card-button-hover"
                        >
                            View Attendance
                        </button>
                        <button
                            onClick={handleEditEvent}
                            className="text-blue-500 hover:text-blue-700 mt-2"
                        >
                            <AiOutlineEdit size={24} />
                        </button>
                    </>
                )}
                {event.author === userId && (
                    <button
                        onClick={handleDeleteEvent}
                        className="text-red-500 hover:text-red-700 mt-2"
                    >
                        <AiOutlineDelete size={24} />
                    </button>
                )}
            </div>
            {isAttendanceModalOpen && (
                <AttendanceModal eventId={event._id} onClose={handleToggleAttendanceModal} />
            )}
        </div>
    );
};

function getMapsLink(address) {
    const encodedAddress = encodeURIComponent(address);

    if (/iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        return `https://maps.apple.com/?q=${encodedAddress}`;
    } else {
        return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    }
}

export function formatEventDate(eventStartUTC, eventEndUTC) {
    const dateOptions = {
        timeZone: 'America/Phoenix',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };

    const timeOptions = {
        timeZone: 'America/Phoenix',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    const eventStart = new Date(eventStartUTC);
    const eventEnd = new Date(eventEndUTC);

    const eventStartDate = new Intl.DateTimeFormat('en-US', dateOptions).format(eventStart);
    const eventStartTime = new Intl.DateTimeFormat('en-US', timeOptions).format(eventStart);
    const eventEndTime = new Intl.DateTimeFormat('en-US', timeOptions).format(eventEnd);

    const now = new Date();
    const todayInArizona = new Intl.DateTimeFormat('en-US', dateOptions).format(now);
    const tomorrowInArizona = new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(now.setDate(now.getDate() + 1)));

    if (eventStartDate === todayInArizona) {
        return `Today ${eventStartTime} - ${eventEndTime}`;
    } else if (eventStartDate === tomorrowInArizona) {
        return `Tomorrow ${eventStartTime} - ${eventEndTime}`;
    } else {
        return `${eventStartDate} ${eventStartTime} - ${eventEndTime}`;
    }
}

export default EventCard;